/** @jsx jsx */
import { jsx } from "theme-ui"
import React, { useState } from "react"
//import "./Tooltip.css";
import useToggle from "../hooks/useToggle"

const Tooltip = props => {
  let timeout
  const [active, setActive] = useToggle()

  const showTip = () => {
    timeout = setTimeout(() => {
      setActive(true)
    }, props.delay || 400)
  }

  const hideTip = () => {
    clearInterval(timeout)
    setActive(false)
  }

  const toggleTip = () => {
    if (active === true) {
      setActive(false)
    } else {
      setActive(true)
    }
  }

  return (
    <div
      className="Tooltip-Wrapper"
      sx={styles}
      // When to show the tooltip
      //onMouseEnter={showTip}
      //onMouseLeave={hideTip}
      onClick={setActive}
      onFocus={setActive}
    >
      {/* Wrapping */}
      {props.children}
      {active && (
        <div className={`Tooltip-Tip ${props.direction || "top"}`}>
          {/* Content */}
          {props.content}
        </div>
      )}
    </div>
  )
}

export default Tooltip

const styles = {
  "& .Tooltip-Tip": {
    position: "absolute",
    borderRadius: "4px",
    padding: 3,
    color: "white",
    background: "black",
    maxWidth: ["auto", "300px", "450px"],
  },
}
